import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import { ProductApi } from "./services/product";
import { AuthApi } from "./services/authUser";
import { AddToCartApi } from "./services/addToCart";
import { OrderApi } from "./services/order";
import { EmployeeApi } from "./services/employee";
import { CoustomerApi } from "./services/coustomer";
import { SalesApi } from "./services/sales";
export const store = configureStore({
  reducer: {
    [ProductApi.reducerPath]: ProductApi.reducer,
    [AuthApi.reducerPath]: AuthApi.reducer,
    [AddToCartApi.reducerPath]: AddToCartApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [EmployeeApi.reducerPath]: EmployeeApi.reducer,
    [CoustomerApi.reducerPath]: CoustomerApi.reducer,
    [SalesApi.reducerPath]: SalesApi.reducer,
    auth: authSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat([
      ProductApi.middleware,
      AuthApi.middleware,
      AddToCartApi.middleware,
      OrderApi.middleware,
      EmployeeApi.middleware,
      CoustomerApi.middleware,
      SalesApi.middleware,
    ]),
});
